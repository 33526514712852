// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ImportExcelModal_modal__-tL-g .modal-body{\n    max-height: calc(100vh - 200px);\n    overflow-y: auto;\n}", "",{"version":3,"sources":["webpack://./src/Components/ImportExcelModal/ImportExcelModal.module.css"],"names":[],"mappings":"AAAA;IACI,+BAA+B;IAC/B,gBAAgB;AACpB","sourcesContent":[".modal :global(.modal-body){\n    max-height: calc(100vh - 200px);\n    overflow-y: auto;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": "ImportExcelModal_modal__-tL-g"
};
export default ___CSS_LOADER_EXPORT___;
