// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".RanIntoProblem_body__rfDYw{background:#774CED; -webkit-user-select:none; user-select:none;display:flex; color:white;  font-weight:600; height: 100vh;}\n.RanIntoProblem_text__PMn34{font-family:Poppins; } \n.RanIntoProblem_wind__S1HLg{ width:400px; margin:auto; margin-top: 10vh;} \n.RanIntoProblem_emoticon__cgHgl{margin-bottom:-7px; font-size:100px; font-weight:100; font-family:Segoe UI} ", "",{"version":3,"sources":["webpack://./src/styles/RanIntoProblem.module.css"],"names":[],"mappings":"AAAA,4BAAM,kBAAkB,EAAE,wBAAgB,EAAhB,gBAAgB,CAAC,YAAY,EAAE,WAAW,GAAG,eAAe,EAAE,aAAa,CAAC;AACtG,4BAAM,mBAAmB,EAAE;AAC3B,6BAAO,WAAW,EAAE,WAAW,EAAE,gBAAgB,CAAC;AAClD,gCAAU,kBAAkB,EAAE,eAAe,EAAE,eAAe,EAAE,oBAAoB","sourcesContent":[".body{background:#774CED; user-select:none;display:flex; color:white;  font-weight:600; height: 100vh;}\n.text{font-family:Poppins; } \n.wind{ width:400px; margin:auto; margin-top: 10vh;} \n.emoticon{margin-bottom:-7px; font-size:100px; font-weight:100; font-family:Segoe UI} "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"body": "RanIntoProblem_body__rfDYw",
	"text": "RanIntoProblem_text__PMn34",
	"wind": "RanIntoProblem_wind__S1HLg",
	"emoticon": "RanIntoProblem_emoticon__cgHgl"
};
export default ___CSS_LOADER_EXPORT___;
