// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Markdown_field__HfS3Z .tox-fullscreen{\n    top: 75px !important;\n    left: 13.5% !important;\n    height: 90vh !important;\n}\n\n@media screen and (max-width: 780px) {\n    .Markdown_field__HfS3Z .tox-fullscreen{\n        left: 0 !important;\n    }\n}\n/* .field :global(.w-md-editor-fullscreen):fullscreen{ \n    position: fixed;\n    top: 200px;\n} */\n\n/* .field :global(:fullscreen){\n    position: absolute;\n    z-index: 2000;\n} */", "",{"version":3,"sources":["webpack://./src/Components/Markdown/Markdown.module.css"],"names":[],"mappings":"AAAA;IACI,oBAAoB;IACpB,sBAAsB;IACtB,uBAAuB;AAC3B;;AAEA;IACI;QACI,kBAAkB;IACtB;AACJ;AACA;;;GAGG;;AAEH;;;GAGG","sourcesContent":[".field :global(.tox-fullscreen){\n    top: 75px !important;\n    left: 13.5% !important;\n    height: 90vh !important;\n}\n\n@media screen and (max-width: 780px) {\n    .field :global(.tox-fullscreen){\n        left: 0 !important;\n    }\n}\n/* .field :global(.w-md-editor-fullscreen):fullscreen{ \n    position: fixed;\n    top: 200px;\n} */\n\n/* .field :global(:fullscreen){\n    position: absolute;\n    z-index: 2000;\n} */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"field": "Markdown_field__HfS3Z"
};
export default ___CSS_LOADER_EXPORT___;
