// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "nav {\n    z-index: 1;\n}\n.Navbar_signOut__orMbV {\n    color: #5932EA;\n    cursor: pointer;\n    font-size: 1.2rem;\n}\n.Navbar_toggle__lbIl1 .btn:focus {\n    background: transparent !important;\n    box-shadow: none !important;\n}\n\n.Navbar_name__znvbG {\n    width: 36px;\n    height: 36px;\n    color: #fff;\n    background: linear-gradient(180deg, #A93BFF 0%, #8834FF 100%);\n    text-transform: uppercase;\n}\n.Navbar_sandwich__U0nxs{\n    display: none;\n}\n\n@media screen and (max-width: 844px) {\n    .Navbar_sandwich__U0nxs{\n        display: inline-block;\n    }\n}\n@media screen and (max-width: 768px) {\n    .Navbar_navItem__qCUpK {\n        flex-direction: row-reverse;\n    }\n    .Navbar_sandwich__U0nxs{\n        display: inline-block;\n    }\n}", "",{"version":3,"sources":["webpack://./src/styles/Navbar.module.css"],"names":[],"mappings":"AAAA;IACI,UAAU;AACd;AACA;IACI,cAAc;IACd,eAAe;IACf,iBAAiB;AACrB;AACA;IACI,kCAAkC;IAClC,2BAA2B;AAC/B;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,WAAW;IACX,6DAA6D;IAC7D,yBAAyB;AAC7B;AACA;IACI,aAAa;AACjB;;AAEA;IACI;QACI,qBAAqB;IACzB;AACJ;AACA;IACI;QACI,2BAA2B;IAC/B;IACA;QACI,qBAAqB;IACzB;AACJ","sourcesContent":["nav {\n    z-index: 1;\n}\n.signOut {\n    color: #5932EA;\n    cursor: pointer;\n    font-size: 1.2rem;\n}\n.toggle :global(.btn:focus) {\n    background: transparent !important;\n    box-shadow: none !important;\n}\n\n.name {\n    width: 36px;\n    height: 36px;\n    color: #fff;\n    background: linear-gradient(180deg, #A93BFF 0%, #8834FF 100%);\n    text-transform: uppercase;\n}\n.sandwich{\n    display: none;\n}\n\n@media screen and (max-width: 844px) {\n    .sandwich{\n        display: inline-block;\n    }\n}\n@media screen and (max-width: 768px) {\n    .navItem {\n        flex-direction: row-reverse;\n    }\n    .sandwich{\n        display: inline-block;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"signOut": "Navbar_signOut__orMbV",
	"toggle": "Navbar_toggle__lbIl1",
	"name": "Navbar_name__znvbG",
	"sandwich": "Navbar_sandwich__U0nxs",
	"navItem": "Navbar_navItem__qCUpK"
};
export default ___CSS_LOADER_EXPORT___;
